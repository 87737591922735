import { LitElement, svg, html, css, property, customElement } from 'lit-element';
import {unsafeSVG} from 'lit-html/directives/unsafe-svg';
import feather from 'feather-icons';

@customElement('trash-icon')
export class TrashIcon extends LitElement {
  @property({attribute: true}) icon;

  static get styles() {
    return css`
      svg {
        color: var(--trash-icon-svg-color);
        fill: var(--trash-icon-svg-fill, none);
        width: var(--trash-icon-svg-width, 24px);
        height: var(--trash-icon-svg-height, 24px);
        stroke-width: var(--trash-icon-svg-stroke-width, 2);
        transition: var(--trash-icon-svg-transition, none);
        overflow: hidden;
        vertical-align: middle;
      }
    `;
  }

  render() {
    if(feather.icons[this.icon]) {
      return svg`
        ${unsafeSVG(feather.icons[this.icon].toSvg())}
      `;
    } else {
      console.error(`trash-icon: invalid icon ${this.icon}`);
      return html`invalid icon ${this.icon}`;
    };
  }
}
