import { LitElement, css, unsafeCSS, property, html, customElement } from 'lit-element';
import c3 from 'c3';
import { timeFormat } from 'd3-time-format';
import style from 'c3/c3.css';

@customElement('trash-plot')
export class TrashPreviewPlot extends LitElement {
  @property({type: Object}) data;

  static get styles() {
    return css`${unsafeCSS(style)}`;
  }

  firstUpdated() {
    this.generatePlot(this.data);
  }

  generatePlot(data) {
    c3.generate({
      bindto: this.renderRoot.querySelector('#graph'),
      size: { 
        height: 400
      },
      padding: {
        left: 20,
        right: 20,
      },
      tooltip: {
        format: {
          title: (d) => {
            const formatDate = timeFormat(("%Y-%m-%d %H:%M"));
            return formatDate(d);
          },
        }
      },
      color: {
        pattern: ["#0168fa", "#8F81F5", "#644EFF", "#98D1EF", "#75C7F3", "#FFE173", "#FFA233"]
      },
      axis: {
        x: {
          show: false
        },
        y: {
          show: false
        }
      },
      data: data,
    });
  }

  render() {
    return html`<div id="graph"></div>`;
  }
}
