import 'lit-flatpickr';
import flatpickr from 'flatpickr';
import { html, LitElement, customElement, property, css } from 'lit-element';

@customElement('trash-range-picker')
export class TrashRangePicker extends LitElement {
  @property({type: String}) fromDate;
  @property({type: String}) toDate;
  @property({type: String}) fromToDate;
  @property({attribute: false}) flatpickrElement;
  @property({attribute: false}) fromInput;
  @property({attribute: false}) toInput;
  @property({attribute: false}) formElement;
  @property({attribute: false}) placeholder;

  connectedCallback(){
    super.connectedCallback();
    flatpickr.l10ns.default.firstDayOfWeek = 1
  }

  firstUpdated(){
    this.flatpickrElement = this.shadowRoot.querySelector('lit-flatpickr');
    this.fromInput = this.shadowRoot.querySelector('#from');
    this.toInput = this.shadowRoot.querySelector('#to');
    this.formElement = this.shadowRoot.querySelector('form');
    this.placeholder = this.shadowRoot.querySelector('#placeholder');

    if(this.fromToDate.length > 0) {
      this.placeholder.innerText = this.fromToDate;
    }
  }

  onDateChange(event) {
    let selectedDates = this.flatpickrElement.getSelectedDates();
    let dateString = this.flatpickrElement.getValue();
    if(selectedDates.length == 2) {
      const strings = dateString.split(" to ");
      this.fromInput.value = strings[0];
      this.toInput.value = strings[1];
      this.formElement.submit();
    }
  }

  onClickPlaceholder(event) {
    this.flatpickrElement.open();
  }

  render() {
    return html`
      <style>
        #wrapper {
          position: relative;
        }

        lit-flatpickr {
          visibility: hidden;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
        }

        :focus-visible {
          outline: 0;
        }

        #placeholder {
          cursor: pointer;
        }
      </style>

      <div id="wrapper">
        <span id="placeholder" @click="${this.onClickPlaceholder}">Select Date Range...</span>
        <lit-flatpickr
          mode="range"
          altInput
          altFormat="F j, Y"
          theme="none"
          @change="${this.onDateChange}">
          <input type="text" id="from-to" placeholder="Select Date Range..." />
        </lit-flatpickr>
      </div>
      <form>
        <input type="hidden" id="from" name="from" />
        <input type="hidden" id="to"  name="to" />
      </form>
    `;
  }
}
